import { Loader } from '@googlemaps/js-api-loader';
export function InitializeGoogleMapApi(apiKey) {
  const loader = new Loader({
    apiKey,
  });
  return loader.importLibrary("places");

  // const script = document.createElement('script');
  // script.async = true;
  // script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
  // document.head.appendChild(script);
  //
  // global.google = window.google;
}
